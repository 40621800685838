import { Component, OnInit, ViewChild } from '@angular/core';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AddressComponent as gAddressComponent } from "ngx-google-places-autocomplete/objects/addressComponent";
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { TokenService } from 'src/app/services/token/token.service';
import Swal from 'sweetalert2'
import { Router } from '@angular/router';
@Component({
  selector: 'app-header-first',
  templateUrl: './header-first.component.html',
  styleUrls: ['./header-first.component.scss']
})
export class HeaderFirstComponent implements OnInit {

  //google place
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  options = {
    types: ['geocode'] // geocode, address, establishment
  }

  address: string = '';

  if_user_activate : boolean = false;

  full_name : string = '';
  constructor(
    private storage:TokenService,
    private route: Router,
  ) {
    this.checkPostCode();
  }

  ngOnInit() {
  }

  checkStatus(){
    let tokan = this.storage.get('auth_token');
    let details = this.storage.get('user_details');
    if(details){
      this.full_name =  this.storage.get('user_details').first_name +" "+this.storage.get('user_details').last_name;
    }
    if(tokan){
      return true;
    }else{
      return false;
    }
  }

  logout(){
    this.storage.remove('auth_token');
    this.route.navigate(['login']);
  }

  public handleAddressChange(addr: Address) {


    let zipcode = (this.findType( addr, "postal_code") != null) ? this.findType( addr, "postal_code").long_name : this.findType( addr, "postal_code");
    const customerAddress = {
      address: addr.formatted_address,
      postal_code: zipcode,
      latitude: addr.geometry.location.lat(),
      longitude: addr.geometry.location.lng()
    }

    if(this.route.url == '/checkout'){
      let address = this.storage.get('location').address;
      if(address != customerAddress.address){
        Swal.fire(
          'Your address miss match compare with previous address!',
          'Your Payment Refresh again',
          'warning'
        );
        this.storage.set('location',customerAddress);
        this.route.navigate(['shopping-cart']);
      }else{
        this.storage.set('location',customerAddress);
      }
    }else{
      this.storage.set('location',customerAddress);
    }

  }

  private findType( addr : Address, type : string ) : any {
    let comp : gAddressComponent = addr.address_components.find((item : gAddressComponent) => item.types.indexOf(type) >= 0);
    if(comp){
      const result = {
        long_name : comp.long_name,
        short_name : comp.short_name,
      }

      return result;
    }else{
      return null;
    }
  }

  checkPostCode(){
    const location = this.storage.get('location');
    if(location){
      this.address = location.address;
    }
  }
}
