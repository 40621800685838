import { Component, OnInit } from '@angular/core';
import { TokenService } from 'src/app/services/token/token.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private token: TokenService
    ) {}

  ngOnInit() {

  }

  cardDataFromStorage = [];
  getCardData(){
    this.cardDataFromStorage = this.token.get('cart_data');
    if(this.cardDataFromStorage){
      return this.cardDataFromStorage = this.token.get('cart_data');
    }else{
      return this.cardDataFromStorage = [];
    }
  }
}
