<nav class="navbar navbar-default" role="navigation">
    <div class="container" style="margin-bottom:20px;">
        <div class="navbar-header">
            <button type="button" class="navbar-toggle" data-toggle="collapse" data-target=".navbar-ex1-collapse">
                <span class="sr-only">Menu Switch</span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
                <span class="icon-bar"></span>
            </button>
            <a class="navbar-brand hidden-md hidden-lg" [routerLink]="['./']"><img src="../../../assets/images/AIOnewLogo2.png" height="60" style="margin-top: -15px;"/></a>
            <a class="navbar-brand hidden-xs hidden-sm" [routerLink]="['./']" style="margin-top: -10px;"><img src="../../../assets/images/AIOnewLogo2.png" style="width: 250px;"/></a>
        </div>

        <img src="../../../assets/images/uk.png" class="flag hidden-xs hidden-sm"/>
        <div class="navbar-header hidden-md hidden-lg">
            <a class="navbar-brand " href="https://apps.apple.com/us/app/aio-shopping/id1599972618#" target="blank"> <img src="../../../assets/images/apple_store.png" style="width: 115px;"/></a>
            <a class="navbar-brand " href="https://play.google.com/store/apps/details?id=com.all_in_one" target="blank"> <img src="../../../assets/images/68650-ipad-play-google-button-iphone-android-now.png" style="width: 115px;
                margin-top: -1px;
                margin-left: -19px;
                height: 36px;"/></a>
        </div>
        <div class="navbar-header hidden-xs hidden-sm" style="margin-left: 145px;
        margin-top: -8px;">
            <a class="navbar-brand " href="https://apps.apple.com/us/app/aio-shopping/id1599972618#" target="blank"> <img src="../../../assets/images/apple.png" style="width: 45px;"/></a>
            <a class="navbar-brand " href="https://play.google.com/store/apps/details?id=com.all_in_one" target="blank"> <img src="../../../assets/images/playstore.png" style="width: 45px;
                height: 40px;
                margin-top: 3px;"/></a>
        </div>
        <!-- Hide for mobile devices -->
        <div class="collapse navbar-collapse navbar-ex1-collapse">
            <!-- Right Menu -->
            <ul  class="nav navbar-nav navbar-right">
                <li >
                    <!-- No SubMenu -->
                    <a routerLink="driver-partner">Driver Partner</a>
                    <!-- <a [routerLink]="sada">asdas</a> -->
                </li>
                <li >
                    <!-- No SubMenu -->
                    <a [routerLink]="'./shop-partner'">Shop Partner</a>
                    <!-- <a [routerLink]="sada">asdas</a> -->
                </li>
                <li >
                    <a class="dropdown-toggle" data-toggle="dropdown">Policies<b class="caret"></b></a>
                    <ul class="dropdown-menu my-dropdown-menu">
                        <li><a [routerLink]="'./privacy-policy'">Privacy Policy</a></li>
                        <li><a [routerLink]="'./terms-conditions'">Terms & Conditions</a></li>
                        <li><a [routerLink]="'./delivery-policy'">Delivery Policy</a></li>
                        <!-- <li><a>Return Policy</a></li> -->
                    </ul>
                    <!-- <a [routerLink]="sada">asdas</a> -->
                </li>
                <li >
                    <!-- No SubMenu -->
                    <a [routerLink]="'./about-us'">About Us</a>
                    <!-- <a [routerLink]="sada">asdas</a> -->
                </li>
                <li >
                    <!-- No SubMenu -->
                    <a [routerLink]="'./contact-us'">Contact Us</a>
                    <!-- <a [routerLink]="sada">asdas</a> -->
                </li>
                <!-- <li >

                    <a >Help Us</a>

                </li>  -->
                <!-- Shopping Cart -->
                <a [routerLink]="'./shopping-cart'" class="cart" style="color: #ffffff;"><i class="fa fa-shopping-cart fa-4x" aria-hidden="true"></i><span class="badge my-badge">{{getCardData().length}}</span></a>
            </ul>
        </div>
    </div>
</nav>


