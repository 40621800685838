import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { AuthGuardGuard } from "./guard/auth-guard/auth-guard.guard";
import { NotAuthGuardGuard } from "./guard/not-auth-guard/not-auth-guard.guard";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    loadChildren: () =>
      import("./pages/home/home.module").then((m) => m.HomeModule),
  },
  {
    path: "categories-of-market/:id/:slug",
    loadChildren: () =>
      import("./pages/shop-sub-category/shop-sub-category.module").then(
        (m) => m.ShopSubCategoryModule
      ),
  },
  {
    path: ":slug/:shopcategory/:shopsubcategory/shops",
    loadChildren: () =>
      import("./pages/shops/shops.module").then((m) => m.ShopsModule),
  },
  {
    path: ":slug/:shopcategory/:shop/product-category",
    loadChildren: () =>
      import("./pages/product-category/product-category.module").then(
        (m) => m.ProductCategoryModule
      ),
  },
  {
    path: ":shopslug/:shopid/:productCategoryId/:productSubCategoryId/product-category/:slugproductcategory",
    loadChildren: () =>
      import("./pages/shop-product/shop-product.module").then(
        (m) => m.ShopProductModule
      ),
  },
  {
    path: ":shopslug/:shopid/:productCategoryId/:productSubCategoryId/product-category/:slugproductcategory/:nameproductsubcategory",
    loadChildren: () =>
      import("./pages/shop-product/shop-product.module").then(
        (m) => m.ShopProductModule
      ),
  },
  {
    path: ":shopslug/:shopid/:productCategoryId/:productSubCategoryId/product-category/:slugproductcategory/:nameproductsubcategory/product/:productid",
    loadChildren: () =>
      import("./pages/single-product/single-product.module").then(
        (m) => m.SingleProductModule
      ),
  },
  {
    path: "shopping-cart",
    loadChildren: () =>
      import("./pages/shopping-cart/shopping-cart.module").then(
        (m) => m.ShoppingCartModule
      ),
  },
  {
    path: "login",
    loadChildren: () =>
      import("./pages/login-page/login-page.module").then(
        (m) => m.LoginPageModule
      ),
    canActivate: [NotAuthGuardGuard],
  },
  {
    path: "registration",
    loadChildren: () =>
      import("./pages/registration/registration.module").then(
        (m) => m.RegistrationModule
      ),
    canActivate: [NotAuthGuardGuard],
  },
  {
    path: "verify-account/:key",
    loadChildren: () =>
      import("./pages/verify-account/verify-account.module").then(
        (m) => m.VerifyAccountModule
      ),
  },
  {
    path: "reset-password",
    loadChildren: () =>
      import("./pages/reset-password/reset-password.module").then(
        (m) => m.ResetPasswordModule
      ),
    canActivate: [NotAuthGuardGuard],
  },
  {
    path: "verify-password/:key",
    loadChildren: () =>
      import("./pages/verify-password/verify-password.module").then(
        (m) => m.VerifyPasswordModule
      ),
    canActivate: [NotAuthGuardGuard],
  },
  {
    path: "dashboard",
    loadChildren: () =>
      import("./pages/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "shop-partner",
    loadChildren: () =>
      import("./pages/shop-registration/shop-registration.module").then(
        (m) => m.ShopRegistrationModule
      ),
  },
  {
    path: "driver-partner",
    loadChildren: () =>
      import("./pages/driver-partner/driver-partner.module").then(
        (m) => m.DriverPartnerModule
      ),
  },
  {
    path: "checkout",
    loadChildren: () =>
      import("./pages/checkout/checkout-page.module").then(
        (m) => m.CheckoutPageModule
      ),
    canActivate: [AuthGuardGuard],
  },
  {
    path: "payment-success",
    loadChildren: () =>
      import("./pages/payament-success/payament-success.module").then(
        (m) => m.PayamentSuccessModule
      ),
  },
  {
    path: "payment-cancel",
    loadChildren: () =>
      import("./pages/payment-cancel/payament-cancel.module").then(
        (m) => m.PayamentCancelModule
      ),
  },
  {
    path: "payment-failed",
    loadChildren: () =>
      import("./pages/payment-failed/payament-failed.module").then(
        (m) => m.PayamentFailedModule
      ),
  },
  {
    path: "about-us",
    loadChildren: () =>
      import("./pages/about-us/about-us.module").then((m) => m.AboutUsModule),
  },
  {
    path: "terms-conditions",
    loadChildren: () =>
      import("./pages/terms-conditions/terms-conditions.module").then(
        (m) => m.TermsConditionsModule
      ),
  },
  {
    path: "privacy-policy",
    loadChildren: () =>
      import("./pages/privacy-policy/privacy-policy.module").then(
        (m) => m.PrivacyPolicyModule
      ),
  },
  {
    path: "delivery-policy",
    loadChildren: () =>
      import("./pages/delivery-policy/delivery-policy.module").then(
        (m) => m.DeliveryPolicyModule
      ),
  },
  {
    path: "contact-us",
    loadChildren: () =>
      import("./pages/contact-us/contact-us.module").then(
        (m) => m.ContactUsModule
      ),
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
