<app-header *ngIf="device_param != 'MOBILE'"></app-header>
<app-header-first *ngIf="device_param != 'MOBILE'"></app-header-first>
<router-outlet></router-outlet>
<app-footer *ngIf="device_param != 'MOBILE'"></app-footer>

<!-- Notification Service -->
<notifier-container></notifier-container>
<!-- spinner -->
<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="large" color="#ffffff" type="ball-atom" [fullScreen]="true"><p style="color:#ffffff" > Loading... </p></ngx-spinner>
<!-- Side Shopping Cart -->
<a *ngIf="device_param != 'MOBILE'" class="hidden-xs cart" [routerLink]="'./shopping-cart'"><span class="counter">{{getCardData().length}}</span></a>