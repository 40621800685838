import { Injectable } from "@angular/core";
import { encode, decode } from "js-base64";
@Injectable({
  providedIn: "root",
})
export class TokenService {
  constructor() {}

  /**
   * @description set data to local storage
   * @param name
   * @param value
   */
  set(name: string, value: any) {
    localStorage.setItem(name, encode(JSON.stringify(value)));
  }

  /**
   * @description get value from local storage
   * @param name
   */
  get(name: string) {
    let details = localStorage.getItem(name);
    if (details) {
      return JSON.parse(decode(details));
    } else {
      return null;
    }
  }

  /**
   * remove storage from storage
   * @param name
   */
  remove(name: string) {
    localStorage.removeItem(name);
  }

  isTokenExpired(token: string): boolean {
    const expiry = JSON.parse(atob(token.split(".")[1])).exp;
    return Math.floor(new Date().getTime() / 1000) >= expiry;
  }
}
