import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Observable, throwError, of } from "rxjs";
import { map, catchError, switchMap } from "rxjs/operators";
import { TokenService } from "../token/token.service";
import { HttpService } from "../http/http.service";
import { NotifierService } from "angular-notifier";
import { environment } from "src/environments/environment";

// @Injectable()
// export class InterceptorInterceptor implements HttpInterceptor {
//   constructor(
//     private tokenService: TokenService,
//     private route: Router,
//     private http: HttpService,
//     private toastrService: NotifierService
//   ) {}

//   intercept(
//     request: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     let access_token = this.tokenService.get("access_token");
//     let auth_token = this.tokenService.get("auth_token");
//     this.tokenService.get("auth_token");

//     if (access_token) {
//       request = request.clone({
//         headers: request.headers.set("Authorization", `Bearer ${access_token}`),
//       });
//     }
//     if (auth_token) {
//       request = request.clone({
//         headers: request.headers.set("Authorization", `Bearer ${auth_token}`),
//       });
//     }
//     // console.log(this.tokenService.get("auth_token"));
//     // Set UserRole header if auth_token exists
//     if (this.tokenService.get("auth_token")) {
//       request = request.clone({
//         headers: request.headers.set(
//           "UserRole",
//           environment.user_types.customer
//         ),
//       });
//     }

//     // Conditionally add headers
//     if (!request.headers.has("Content-Type")) {
//       request = request.clone({
//         headers: request.headers.set("Content-Type", "application/json"),
//       });
//     }

//     // Handling specific response type and accept headers only for specific endpoints
//     if (request.url.includes("/specific-endpoint-for-pdf")) {
//       request = request.clone({
//         headers: request.headers.set("Accept", "application/pdf"),
//         responseType: "blob" as "json", // casting needed for Angular's HttpClient typing
//       });
//     }

//     return next.handle(request).pipe(
//       map((event: HttpEvent<any>) => {
//         if (event instanceof HttpResponse) {
//           // Handle successful responses if needed
//         }
//         return event;
//       }),
//       catchError((error: HttpErrorResponse) => {
//         if (error.status === 401) {
//           // Handle 401 Unauthorized: try refreshing the token
//           this.toastrService.notify(
//             "error",
//             "Session expired. Refreshing session..."
//           );
//           return this.handle401Error(request, next);
//         }
//         // Handle other errors as necessary
//         return throwError(error);
//       })
//     );
//   }

//   private handle401Error(
//     request: HttpRequest<any>,
//     next: HttpHandler
//   ): Observable<HttpEvent<any>> {
//     // Refresh the token and retry the request
//     return this.http.getAccessToken().pipe(
//       switchMap((data: any) => {
//         // Set new access token in storage
//         this.tokenService.set("access_token", data.access_token);

//         // Clone the request with the new token and retry
//         const clonedRequest = request.clone({
//           headers: request.headers.set(
//             "Authorization",
//             `Bearer ${data.access_token}`
//           ),
//         });
//         return next.handle(clonedRequest);
//       }),
//       catchError((err) => {
//         // If refresh also fails, log out the user
//         this.tokenService.remove("auth_token");
//         this.tokenService.remove("access_token");
//         this.route.navigate(["/login"]);
//         return throwError(err);
//       })
//     );
//   }
// }
@Injectable()
export class InterceptorInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private route: Router,
    private http: HttpService,
    private toastrService: NotifierService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Fetch tokens
    let access_token = this.tokenService.get("access_token");
    let auth_token = this.tokenService.get("auth_token");

    // Use only the access_token for authorization if it exists and is not expired
    if (auth_token && !this.tokenService.isTokenExpired(auth_token)) {
      request = request.clone({
        headers: request.headers.set("Authorization", `Bearer ${auth_token}`),
      });
      request = request.clone({
        headers: request.headers.set(
          "UserRole",
          environment.user_types.customer
        ),
      });
    } else if (
      access_token &&
      !this.tokenService.isTokenExpired(access_token)
    ) {
      request = request.clone({
        headers: request.headers.set("Authorization", `Bearer ${access_token}`),
      });
    }

    // Conditionally set other headers as needed
    if (!request.headers.has("Content-Type")) {
      request = request.clone({
        headers: request.headers.set("Content-Type", "application/json"),
      });
    }

    if (request.url.includes("/specific-endpoint-for-pdf")) {
      request = request.clone({
        headers: request.headers.set("Accept", "application/pdf"),
        responseType: "blob" as "json",
      });
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          // Optional success handling
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.toastrService.notify(
            "error",
            "Session expired. Refreshing session..."
          );
          return this.handle401Error(request, next);
        }
        return throwError(error);
      })
    );
  }

  private handle401Error(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return this.http.getAccessToken().pipe(
      switchMap((data: any) => {
        if (data && data.access_token) {
          this.tokenService.set("access_token", data.access_token);
          const clonedRequest = request.clone({
            headers: request.headers.set(
              "Authorization",
              `Bearer ${data.access_token}`
            ),
          });
          return next.handle(clonedRequest);
        }
        return throwError("Failed to refresh token");
      }),
      catchError((err) => {
        this.tokenService.remove("auth_token");
        this.tokenService.remove("access_token");
        this.route.navigate(["/login"]);
        return throwError(err);
      })
    );
  }
}
