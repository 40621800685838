<nav class="navbar navbar-default" role="navigation">
    <div class="container" style="margin-bottom: 11px;">
        <div class="navbar-header">
            <div class="inputWithIcon">
                <input class="form-control form-control-lg search-form-style" type="text" placeholder="Search By PostCode" ngx-google-places-autocomplete [options]="options" #placesRef="ngx-places" (onAddressChange)="handleAddressChange($event)" [(ngModel)]="address"/>
                <i class="fa fa-map-marker fa-lg fa-fw" aria-hidden="true"></i>
              </div>
        </div>
        <!-- Hide for mobile devices -->
        <div class="collapse navbar-collapse navbar-ex1-collapse">
            <!-- Right Menu -->
            <!-- Right Menu -->
            <ul  class="nav navbar-nav navbar-right">
                <li >
                    <!-- No SubMenu -->
                    <a routerLink="home"><i class="fa fa-home" aria-hidden="true"></i> Super Market</a>
                </li> 
                <!-- <li *ngIf="!checkStatus()">
                    <a style="cursor:not-allowed"> Restaurant & Takeaway</a>
                </li>  -->
                <!-- <li *ngIf="!checkStatus()">
                    <a style="cursor:not-allowed"> Electronic Devices and Parts</a>
                </li>  -->
                <!-- <li >
                    <a style="cursor:not-allowed"><i class="fa fa-home" aria-hidden="true"></i> Part & Gift Shops</a>
                </li>  -->
                <li *ngIf="checkStatus()">
                    <!-- No SubMenu -->
                    <a routerLink="dashboard"><i class="fa fa-tachometer" aria-hidden="true"></i> Dashboard</a>
                </li> 
                <li *ngIf="!checkStatus()">
                    <!-- No SubMenu -->
                    <a routerLink="login"><i class="fa fa-user" aria-hidden="true"></i> Sign In</a>
                    <!-- <a [routerLink]="sada">asdas</a> -->
                </li>   
                <li *ngIf="checkStatus()">
                    <!-- No SubMenu -->
                    <a class="dropdown-toggle" data-toggle="dropdown" style="background-color: transparent;"><i class="fa fa-user" aria-hidden="true"></i> {{full_name}}</a>
                    <ul class="dropdown-menu my-dropdown-menu">
                        <li><a (click)="logout()" style="cursor: pointer;">Sign Out</a></li>
                        <!-- <li><a>Return Policy</a></li> -->
                    </ul>
                </li>    
                <!-- <li >
   
                    <a ><i class="fa fa-sign-in" aria-hidden="true"></i> Sign Up</a>

                </li>     -->
            </ul>
        </div>
    </div>
</nav>


