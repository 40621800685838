<footer class="footer-section">
    <div class="container">
        <!-- <div class="footer-cta pt-5 pb-5" style="margin-bottom: 5px;">
            <div class="row">
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class="fas fa-map-marker-alt"></i>
                        <div class="cta-text">
                            <h4>Find us</h4>
                            <span>1010 Avenue, sw 54321, chandigarh</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class="fas fa-phone"></i>
                        <div class="cta-text">
                            <h4>Call us</h4>
                            <span>9876543210 0</span>
                        </div>
                    </div>
                </div>
                <div class="col-xl-4 col-md-4 mb-30">
                    <div class="single-cta">
                        <i class="far fa-envelope-open"></i>
                        <div class="cta-text">
                            <h4>Mail us</h4>
                            <span>mail@info.com</span>
                        </div>
                    </div>
                </div>
            </div>
        </div> -->
        <div class="footer-content pt-5 pb-5" style="margin-top: 5px;">
            <div class="row">
                <div class="col-xs-12  col-xl-4 col-lg-4 mb-50">
                    <div class="footer-widget">
                        <div class="footer-logo">
                            <a routerLink="home"><img src="../../../assets/images/AIOnewLogo2.png" class="img-fluid" alt="logo" style="margin-top: 15px;"></a>
                        </div>
                        <div class="footer-text">
                            <p>
                                All in One Roof started online platform for all kind of delivery service to your doorstep. Explore our website and app to find out about how rapid we deliver for your all kind of orders. <a  routerLink="about-us" style="color: aliceblue;">More Read ></a>
                            </p>
                        </div>
                        <div class="footer-social-icon">
                            <span>Follow us</span>
                            <a href="#"><i class="fa fa-facebook-official" aria-hidden="true"></i></a>
                            <a href="#"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                            <a href="#"><i class="fa fa-google-plus" aria-hidden="true"></i></a>
                        </div>
                    </div>
                </div>
                <div class="col-xs-12  col-xl-4 col-lg-4 col-md-6 mb-30">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Useful Links</h3>
                        </div>
                        <ul>
                            <li><a routerLink="home">Markets</a></li>
                            <li><a routerLink="privacy-policy">Terms</a></li>
                            <li><a routerLink="terms-conditions">Policy</a></li>
                            <li><a routerLink="delivery-policy">Delivery Policy</a></li>
                            <li><a routerLink="about-us">About Us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-xs-12 col-xl-4 col-lg-4 col-md-6 mb-50">
                    <div class="footer-widget">
                        <div class="footer-widget-heading">
                            <h3>Payment Options</h3>
                        </div>
                        <div class="footer-text mb-25">
                            <div class="row">
                                <div class="col-md-2 col-xs-2"><img src="../../../assets/images/visa.png" style="width: 50px;" alt=""></div>
                                <div class="col-md-2 col-xs-2"><img src="../../../assets/images/masterc.png" style="width: 50px;" alt=""></div>
                                <div class="col-md-2 col-xs-2"><img style="width: 50px;height: 31px;" src="../../../assets/images/paypalm.png" alt=""></div>
                                <div class="col-md-6 col-xs-6"></div>
                            </div>
                            <br>
                        </div>
                        <div class="footer-social-icon">
                            <span>Download App</span>
                            <div class="flex social-btns">
                                <a target="blank" href="https://apps.apple.com/us/app/aio-shopping/id1599972618#">
                                  <img src="../../../assets/images/apple_store.png" style="width: 128px;"/>
                                </a>
                                <a target="blank" href="https://play.google.com/store/apps/details?id=com.all_in_one">
                                    <img src="../../../assets/images/68650-ipad-play-google-button-iphone-android-now.png" style="width: 128px;"/>
                                  </a>
                            </div>
                        </div>
                        <br>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="copyright-area">
        <div class="container">
            <div class="row">
                <div class="col-xl-6 col-lg-6 text-center text-lg-left">
                    <div class="copyright-text">
                        <p>Copyright &copy; 2021, All Right Reserved <a href="https://allinoneroof.co.uk">allinoneroof</a></p>
                    </div>
                </div>
                <div class="col-xl-6 col-lg-6 d-none d-lg-block text-right">
                    <div class="footer-menu">
                        <ul>
                            <li><a routerLink="home">Markets</a></li>
                            <li><a routerLink="privacy-policy">Terms</a></li>
                            <li><a routerLink="terms-conditions">Policy</a></li>
                            <li><a routerLink="delivery-policy">Delivery Policy</a></li>
                            <li><a routerLink="about-us">About Us</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>