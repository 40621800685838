import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import {
  Customer,
  LoginEmail,
  OauthRequest,
  QueryParams,
  ResendVerify,
  ResetEmail,
  Verify,
  VerifyPassword,
  CheckAndAssignUser,
} from "src/app/type/request";
import {
  ApiResponse,
  AccessTokenResponse,
  AuthTokenResponse,
} from "src/app/type/reponse";
@Injectable({
  providedIn: "root",
})
export class HttpService {
  constructor(private http: HttpClient) {}

  /**
   *
   * @description get access token
   */
  getAccessToken() {
    const Oauth: OauthRequest = {
      grant_type: "client_credentials",
      client_id: environment.credential.access.client_id,
      client_secret: environment.credential.access.secret_key,
      scope: "",
    };
    return this.http.post<AccessTokenResponse>(
      `${environment.backend.base_url + environment.backend.auth_slug}`,
      Oauth
    );
  }

  // /**
  //  *
  //  * @param QueryParams
  //  * @description get advertinment
  //  */
  // getAdvertinment(QueryParams:QueryParams){
  //   return this.http.get<ApiResponse>(`${environment.backend.base_url+environment.backend.resources_slug}/advertisment`,{
  //     params: <any>QueryParams
  //   });
  // }

  /**
   *
   * @param QueryParams
   * @description get shop category
   */
  getShopCategory(QueryParams: QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop-category`,
      {
        params: <any>QueryParams,
      }
    );
  }

  /**
   *
   * @param slug
   * @description get shop category by slug
   */
  getShopCategoryBySlug(slug: string) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop-category/${slug}`
    );
  }

  /**
   *
   * @param QueryParams
   * @description get shop sub category
   */
  getShopSubCategory(QueryParams: QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop-sub-category`,
      {
        params: <any>QueryParams,
      }
    );
  }

  /**
   *
   * @param slug
   * @description get shop sub category by slug
   */
  getShopSubCategoryBySlug(slug: string) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop-sub-category/${slug}`
    );
  }

  /**
   *
   * @param QueryParams
   * @description get shops
   */
  getShops(QueryParams: QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop`,
      {
        params: <any>QueryParams,
      }
    );
  }

  /**
   *
   * @param slug
   * @description get shops
   */
  getShopById(slug: string) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop/${slug}`
    );
  }

  /**
   *
   * @param QueryParams
   * @description get product category
   */
  getProductCategory(QueryParams: QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/product-category`,
      {
        params: <any>QueryParams,
      }
    );
  }

  /**
   *
   * @param QueryParams
   * @description get product sub category
   */
  getProductSubCategory(QueryParams: QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/product-sub-category`,
      {
        params: <any>QueryParams,
      }
    );
  }

  /**
   *
   * @param QueryParams
   * @description get shop product
   */
  getShopProduct(QueryParams: QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop-product`,
      {
        params: <any>QueryParams,
      }
    );
  }

  /**
   *
   * @param id
   * @description get product by id
   */
  getProductById(id: string) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop-product/${id}`
    );
  }

  /**
   *
   * @param getDeliveryCharge
   * @description get delivery charge
   */
  getDeliveryCharge(QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/delivery-charge`,
      {
        params: <any>QueryParams,
      }
    );
  }

  /**
   *
   * @param getDeliveryCharge
   * @description get delivery charge
   */
  customerRegistration(RequestBody: Customer) {
    return this.http.post<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/user-register`,
      RequestBody
    );
  }

  /**
   *
   * @param Verify
   * @description verify account
   */
  verify(RequestBody: Verify) {
    return this.http.post<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/verify`,
      RequestBody
    );
  }

  /**
   *
   * @param resendOtp
   * @description resend account
   */
  resendOtp(RequestBody: ResendVerify) {
    return this.http.post<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/resend-verify`,
      RequestBody
    );
  }

  /**
   *
   * @param resendOtp
   * @description resend account
   */
  resetPassword(RequestBody: ResetEmail) {
    return this.http.post<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/reset-password`,
      RequestBody
    );
  }

  /**
   *
   * @param resendOtp
   * @description verify account
   */
  verifyPassword(RequestBody: VerifyPassword) {
    return this.http.post<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/verify-password`,
      RequestBody
    );
  }

  /**
   *
   * @param resendOtp
   * @description login
   */
  login(RequestBody: LoginEmail) {
    return this.http.post<AuthTokenResponse>(
      `${environment.backend.base_url + environment.backend.auth_slug}`,
      RequestBody
    );
  }

  /**
   *
   * @param getUser
   * @description get auth user
   */
  getUser() {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/auth-user`
    );
  }

  /**
   *
   * @param RequestBody
   * @description order submit
   */
  order(RequestBody: any) {
    return this.http.post<AuthTokenResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/order?platform_type=WEB`,
      RequestBody
    );
  }

  /**
   *
   * @param QueryParams
   * @description get orders
   */
  getOrders(QueryParams: QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/order`,
      {
        params: <any>QueryParams,
      }
    );
  }

  /**
   *
   * @param RequestBody
   * @description shop registration
   */
  shopRegistration(RequestBody) {
    return this.http.post<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/shop`,
      RequestBody
    );
  }

  /**
   *
   * @param CheckAndAssignUser
   * @description check user
   */
  checkUser(CheckAndAssignUser: any) {
    return this.http.post<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/check-user`,
      CheckAndAssignUser
    );
  }

  /**
   *
   * @param CheckAndAssignUser
   * @description assign user
   */
  assignUser(CheckAndAssignUser: CheckAndAssignUser) {
    return this.http.post<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/assign-user`,
      CheckAndAssignUser
    );
  }

  /**
   *
   * @param QueryParams
   * @description get banners
   */
  getBanners() {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/banners`
    );
  }

  /**
   *
   * @param code
   * @returns
   */
  staticDataGet(code: string) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/static/` + code
    );
  }

  /**
   *
   * @param getRelativeProduct
   */
  getRelativeProduct(QueryParams) {
    return this.http.get<ApiResponse>(
      `${
        environment.backend.base_url + environment.backend.resources_slug
      }/related-product`,
      {
        params: <any>QueryParams,
      }
    );
  }
}
