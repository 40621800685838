import { NgModule,CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
 
import { AppRoutingModule } from './app-routing.module';
import {RouterModule} from '@angular/router';
import { AppComponent } from './app.component';
//notify
import { NotifierModule } from 'angular-notifier';
//http
import { HttpClientModule,HTTP_INTERCEPTORS } from '@angular/common/http';
//spiner
import { NgxSpinnerModule } from "ngx-spinner";
//components
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderFirstComponent } from './components/header-first/header-first.component';
//interceptor
import { InterceptorInterceptor } from './services/interceptor/interceptor.interceptor';

//google
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { NgBootstrapFormValidationModule } from 'ng-bootstrap-form-validation';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    HeaderFirstComponent
  ],
  imports: [
    GooglePlaceModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    RouterModule,
    NotifierModule,
    HttpClientModule,
    NgxSpinnerModule,
    BrowserAnimationsModule,
    NgBootstrapFormValidationModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: InterceptorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
