// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
// base_url: 'https://devaioapi.jaffnait.com',
// base_url_image: 'https://devaioapi.jaffnait.com/storage/',
// pdf_logo_image: 'https://devaioapi.jaffnait.com/new2logo.png',
export const environment = {
  production: false,
  // backend config
  backend: {
    base_url: "https://allinoneapi.allinoneroof.co.uk",
    base_url_image: "https://allinoneapi.allinoneroof.co.uk/storage/",
    pdf_logo_image: "https://allinoneapi.allinoneroof.co.uk/new2logo.png",
    auth_slug: "/oauth/token",
    resources_slug: "/api/v1",
  },
  //user types
  user_types: {
    customer: "CUMR",
    delivery: "DLVY",
    shop_user: "SHPA",
  },
  //credentials
  credential: {
    access: {
      client_id: 3,
      secret_key: "3UpdKl8nSrAdKKA0jpNaAznq2oKFKPYQMg2sFHbH",
    },
    auth: {
      client_id: 2,
      secret_key: "k83BVWJd40c2Xa222TdtzsDKr0GyVqTpZXzvgUZr",
    },
  },
  google_key: "AIzaSyAeMeT6TBPOL28mZH-HRb04sChf13e6zC4",
  static: {
    ABOUT_US: "ABOUT_US",
    PRIVACY_POLICY: "PRIVACY_POLICY",
    TERMS: "TERMS",
    DELIVERY_POLICY: "DELIVERY_POLICY",
    CONTACT_US: "CONTACT_US",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
