import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { HttpService } from './services/http/http.service';
import { TokenService } from './services/token/token.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  device_param : string = '';

  constructor(
    private spinner: NgxSpinnerService,
    private token: TokenService,
    private route: ActivatedRoute,
    private http: HttpService) {
      this.getToken();
    }

  ngOnInit() {
    this.spinner.show();
    this.spinner.hide();
    this.route.queryParams
      .subscribe(params => {
        this.device_param = params.DEVICE;
      }
    );
  }

  getToken(){
    this.http.getAccessToken().subscribe((data:any)=>{
      this.token.set('access_token',data.access_token);
      console.log(data);
    })
  }

  cardDataFromStorage = [];
  getCardData(){
    this.cardDataFromStorage = this.token.get('cart_data');
    if(this.cardDataFromStorage){
      return this.cardDataFromStorage = this.token.get('cart_data');
    }else{
      return this.cardDataFromStorage = [];
    }
  }
}
